import React, { useEffect } from 'react'
import styled from 'styled-components'
import AOS from 'aos';
import 'aos/dist/aos.css';
function About({ language }) {

    useEffect(() => {
        AOS.init();
      }, [])

    const aboutInfo = [
        {
            aboutUs: `"JOVI" mas’uliyati cheklangan jamiyati "JOVI" tovar belgisining huquqiy sohibi hisoblanib, qurilish materiallari bozorida yetakchi va oily darajadagi sifatli mahsulotlar ishlab chiqaruvchilardan biri bo’lib tanilgan.

      "JOVI" 4 yildan ortiq bo’lgan faoliyati davomida boy tajriba orttirib, hozirgi vaqtda 5 xil turdagi qurilish va pardozlash materiallarni ishlab chiqarmoqda.
      
      Mahsulotlar to’plami – turli keramik, marmar, granit, mayolika va travertin plitkalarini yopishtirish uchun qorishmalar, dekorativ fasad qoplamalari, quyiluvchi pol, gidroizolyatsiya materiallari, g’isht-tosh terish uchun qorishmalar, turli bog’lovchi gruntovkalar, maxsus suv emulsiya bo’yoqlari va boshqalardan iboratdir.
      
      Ta’kidlash joizki, "JOVI" mahsulotlarining sifat darajasi quruvchi tashkilotlar, mutaxassislar va oddiy iste’molchilar tomonidan doimiy ravishda yuqori baholanib, e’tirof etib kelinmoqda.
      
      Ishlab chiqarilgan mahsulotlar mamlakatimizning ichki bozoriga yetkazib berilmoqda, shuningdek, Qozoqiston kabi chet el mamlakatlariga eksport qilinmoqda.
      
      Barcha mahsulotlar ISO 9001:2008 sertifikatiga asosan sifat menejmenti ostida, hamda, O’zbekiston Respublikasi sifat standartlariga muvofiq ishlab chiqarilmoqda`,
        },
        {
            aboutUs: `ООО "JOVI" является правообладателем торговой марки "JOVI" - одним из крупнейших производителей Узбекистана, специализирующихся на производстве высокоэффективных строительно-отделочных материалов.

      На протяжении своей деятельности более чем 4 лет "JOVI" накопил богатейший опыт работы в сфере производства строительно-отделочных материалов, и сегодня список ассортимента достиг 5 наименований готовой продукции. Это не только стандартные сухие строительные смеси, такие как шпаклевка или штукатурка, но и клеи для керамических, гранитных, мозаичных и т.п. плиток, кладочный раствор, наливной пол, гидроизоляционные материалы, декоративные фасадные покрытия, специальные грунтовки, водоэмульсионные краски, обойные клеи и другие.
      
      Продукция "JOVI" является строительно-отделочными материалами премиум класса, качество которой было высоко оценено как строителями-специалистами, так и индивидуальными потребителями.
      
      Производимая продукция пользуется спросом не только во всех регионах Республики Узбекистан, но и экспортируется в страны такие как Казахстан и другие страны ближнего зарубежья.
      
      Вся линейка продукции производится на основе системы менеджмента качества ISO 9001:2008, сертификатов соответствия государственным стандартам Республики Узбекистан.`,
        },
        {
            aboutUs: `"JOVI" LTD is the legal owner of trade mark  "JOVI" - one of the largest producers of Uzbekistan, specialized on producing high-efficiency construction-finishing materials.

    During its operation of more than 10 years  "JOVI" has accumulated the richest experience in the field of construction-finishing materials. Today the list of assortment includes over 5 final product names. It is not only dry mixtures, such as a putty or plaster, but also glues for ceramic, granite and tessellated tiles, laying solution, hydraulic insulating materials, decorative facade coverage, special prime coating, water paints, wallpaper glues etc.
    
    Products of "JOVI"are premium class. Its quality was highly evaluated by both builders-specialists and individual consumers.
    
    Manufactured products are on demand not only in Uzbekistan, but also are exported to countries such as Kazakhstan and other neighboring countries.
    All product lines are based on the management system quality certificates ISO 9001: 2008, in accordance with the state standards of Republic of Uzbekistan.`,
        }
    ]

    return (
        <Wrapper data-aos="zoom-in">
            <h1>
                {language === 0 ? 'Biz haqimizda'
                : language === 1 ? 'О Компании' : 'About us'}
            </h1>
            <p>{aboutInfo[language].aboutUs}</p>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    padding: 10px 25px;
    max-width: 1440px;
    min-height: 60vh;
    margin: auto;
    text-align: center;

    h1, p {
        color: white;
    }

    p {
        font-size: 22px;
    }
`

export default About