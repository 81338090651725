import Product1 from './images/finish-shpatel2.png'
import Product2 from './images/gips-shkaturka2.png'
import Product3 from './images/glat2.png'
import Product4 from './images/naliv-pol2.png'
import Product5 from './images/usil-kley-extra2.png'

export const products = [
    [
        {
            title: 'Yakuniy shpatlyevka',
            description:'Tarkibi gips asosidagi va maxsus kimyoviy moddalar qo’shilgan ГОСТ 31387-2008 bo’yicha ishlab chiqarilgan quruq qurilish qorishmasi. Yuqori darajada yopishish xususiyatiga ega. Sement yoki gips suvog`i bilan ishlov berilgan yoki berilmagan beton hamda tekis yuzalarga so’nggi qatlam sifatida ishlatiladi. Qorishma bir qatlamda 1 mm qalinlikda surtiladi. Bo’yashdan yoki devor gulqogozi yopishtirishdan avval gruntlashga ehtiyoj bo’lmagan tekis va silliq yuza hosil qiladi. Qurigandan so’ng yoriq hosil qilmaydi. Havo o’tkazuvchanlik xususiyatiga ega bo’lganligi tufayli namlik muvozanatini saqlab turadi. Juda yengil va issiqlik saqlash hususiyatiga ega. Yig’ilib qolmaydi va oqib tushmaydi. Yong’inga qarshi kechiktiruvchi xususiyati bor',
            usage: `
            Suv qo’shish miqdori: 5-6 litr suvga 10 kg quruq qorishma.
            Qorishmaning qotishini boshlanishi: 180-200 daqiqa.
            Sarflanishi: 1 mm qalinlikdagi qatlamga 1 kg/m2.
            Siqilishga mustahkamliligi: 2,0 МPа.
            Yuzaga  yopishish mustahkamligi: 0,3 MPa.
            Ishlash joyidagi muhit harorati: +5°C/+35°C bo’lishi lozim.
            `,
            img: Product1,
        },
        {
            title: 'Gips shkaturka',
            description:`Tarkibi Gips asosidagi ГОСТ 31377-2008 bo’yicha ishlab chiqarilgan quruq suvoq qorishmasi. Bino devorlarning ichki tomonidagi g’isht, beton, tosh va shunga o’xshash yuzalarni tekislash va silliqlash maqsadida 2 sm dan 13 sm gacha qalinlikda suvash uchun qo’llaniladi. Yuqori darajada yopishish xususiyatiga ega. Oqib tushmaydi va to’planib qolmaydi. Havo o’tkazuvchanlik xususiyatiga ega bo’lganligi tufayli namlik muvozanatini saqlab turadi. Issiqlikni saqlash, energiyani tejash va yong'inga qarshi kechiktiruvchi xususiyatlariga ega.`,
            usage: `Suv qo’shish miqdori: 4,5-5,5 litr suvga 10 kg suvoq qorishmasi.
            Qorishmaning qotishini boshlanishi: kamida 60 daqiqa.
            To'liq qotish vaqti: 24 soat.
            Sarflanishi: 1 sm qalinlikdagi qatlamga 10 kg/m2.
            Siqilishga mustahkamliligi: 2,0 МPа.
            Yuzaga yopishish mustahkamligi: 0,3 MPa.
            Ishlash joyidagi muhit harorati: +5°C/+35°C bo’lishi lozim.`,
            img: Product2,
        },
        {
            title: 'Glatt',
            description:`Tarkibi gips asosidagi va kimyoviy yordamchi moddalardan iborat ГОСТ 31387-2008 bo’yicha ishlab chiqarilgan suvoq qorishmasi. Yuqori darajali yopishish xususiyatiga ega. Boshqa materiallarni (kimyoviy moddalar) qo’shimcha qoshilishi uchun ehtiyoji yoq. Qurilish, ta’mirlash va tiklash ishlarida g’isht, beton, gazbeton va shunga o’xshash yuzalarga bino devorining ichki tomonidagi yuzasiga 5 sm, shiftlarga 3 sm gacha qalinlikda ishlatiladi. Oqib tushmaydi va to’planib qolmaydi. Havo o’tkazuvchanlik xususiyatiga ega bo’lganligi tufayli namlik muvozanatini saqlab turadi. Juda yengil va issiqlik saqlash xususiyatiga ega. Yong’inga qarshi kechiktiruvchi xususiyati bor.`,
            usage: `Suv qo’shish miqdori: 5-6 litr suvga 10 kg suvoq qorishmasi.
            Qorishmaning qotishini boshlanishi: 120-150 daqiqa.
            Sarflanishi: 1 sm qalinlikdagi qatlamga 10 kg/m2.
            Siqilish mustahkamligi: 2,0 МPа.
            Yuzaga yopishish mustahkamligi: 0,3 MPa.
            Ishlash joyidagi muhit harorati: +5°C/+35°C bo’lishi lozim.`,
            img: Product3,
        },
        {
            title: 'Suyuq pol',
            description:"Tarkibi sement asosidagi polimer moddalar bilan boyitilgan, ГОСТ 31358-2007 bo’yicha ishlab chiqarilgan maxsus quruq qorishma. Binolar va inshootlarni qurishda, ta`mirlashda va tiklashda har xil turdagi pollarni yotkazishdan oldin, yuzalarni tayorlash uchun mo`ljallangan, o`zi zichlashuvchanlik xususiyatga ega mahsulot. Beton va boshqa turdagi asoslarga nisbatan yuqori yopishuvchanlik xususiyatiga ega. Mahsulotning antibakterial xususiyatlari har xil turdagi zamburug` va bakteriyalarni paydo bo`lishini oldini oladi. Qo`llanishi onson, ortiqcha ish kuchini talab etmaydi. Yuqori darajadagi mustahkamlikka ega.",
            usage: `Suv qo’shish miqdori: 2,4–3,0 litr suvga 10 kg quruq qorishma.
            Ishlatish vaqti: 60 daqiqa.
            Sarflanishi: 1 mm qalinligdagi qatlamga 1,7 kg/м2.
            Yuzaga yopishish mustahkamligi: 0,6 MPa.
            Ishlash joyidagi muhit harorati: +5°C/+35°C bo’lishi lozim.`,
            img: Product4,
        },
        {
            title: 'Ekstra kley',
            description:'Tarkibi sement asosidagi polimer hamda qo’shimcha kimyoviy moddalar bilan boyitilgan maxsus quruq qorishma. Binolarning tashqi va ichki qismlarida, yotiq va tik yuzalarga, hovuz, hammom, suv ombori kabi nam muhitlarda, beton, suvoq yuzalarga fayans, sopol, marmar, granit plitkalar va shishali mozaika kabi materiallarni yopishtirish uchun ishlatiladi. Haroratning keskin o’zgarishi yoki muzlash holati qorishmaning xususiyatini o’zgartirmaydi.',
            usage: `Suv qo’shish miqdori: 2,7–3,5 litr suvga 10 kg quruq qorishma.
            Ishlatish vaqti: 120-180 daqiqa.
            Sarflanishi: Plitkaning o’lchamlariga ko’ra 4-5 kg/m2.
            Yuzaga yopishish mustahkamligi: 0,5 MPa.
            Ishlash joyidagi muhit harorati: +5°C/+35°C bo’lishi lozim`,
            img: Product5,
        },
    ],
    [
        {
            title: 'Финиш шпатлевка',
            description:'Сухая строительная шпаклевочная смесь, изготавливаемая на гипсовом вяжущем с различными добавками по ГОСТу 31387-2008. Обладает высокими клеящимися свойствами. Предназначена для подготовки поверхностей под отделку при проведении внутренних работ на бетоне, цементной стяжке или гипсовой штукатурке и на других поверхностях гипсовых оснований. Толщина слоя за одно нанесение –1 мм. Создает ровную и гладкую не нуждающуюся в грунтовке поверхность, для  покраски или наклеивания обоев. При высыхании не дает трещин. Пропускает воздух и за счет этого балансирует уровень влажности. Очень легкий, обеспечивает теплоизоляцию. Не стекает и не собирается. Имеет противопожарные свойства.',
            usage: `Перемешивание с водой: 5-6 литров на 10 кг смеси.
            Срок схватывания раствора: 180-200 минут.
            Площадь применения: 1 кг/м2, при толщине слоя 1 мм.
            Прочность на сжатие: 2,0 МПа.
            Прочность сцепления с основанием: 0,3 МПа.
            Необходимая температура рабочей среды: +5°С/+35°С.`,
            img: Product1,
        },
        {
            title: 'Гипсовая Шкатурка',
            description:`Сухая штукатурная смесь на гипсовой основе, изготовлена по ГОСТу 31377-2008. Используется при проведении внутренних работ: для выравнивания и оштукатуривания каменных, кирпичных,  гипсовых, бетонных, монолитно-бетонных и т.п. стен. Толщина наносимого слоя от 2 см до 13 см. Обладает высокими клеящимися свойствами. Не стекает и не собирается. Пропускает воздух и за счет этого балансирует уровень влажности в помещениях. Обеспечивает теплоизоляцию. Имеет энергосберегающие и противопожарные свойства.`,
            usage: `Перемешивание с водой: на 4,5-5,5 л воды 10 кг смеси.
            Срок схватывания раствора: не менее 60 минут.
            Время высыхания: 24 часа.
            Площадь применения: 10 кг/м2, при толщине слоя 1 см.
            Прочность на сжатие: 2,0 МПа.
            Прочность сцепления с основанием: 0,3 МПа.
            Необходимая температура рабочей среды: +5°С /+35°C`,
            img: Product2,
        },
        {
            title: 'Глатт',
            description:`Сухая универсальная штукатурочная смесь на гипсовой основе с различными добавками, изготовлена по ГОСТу 31387-2008. Обладает высокой степенью клейкости и не нуждается в дополнительных материалах. Можно использовать непосредственно на поверхность кирпичной кладки, бетона, газобетона и т.п. при проведении внутренних работ,  строительстве, ремонте и реконструкции зданий и сооружений толщиной слоя на стенах - 5см, а на потолке до 3-х см. Не стекает и не собирается. Пропускает воздух и за счет этого балансирует уровень влажности. Очень легкий, обеспечивает теплоизоляцию. Имеет противопожарные свойства.`,
            usage: `Перемешивание с водой: 5-6 литров на 10 кг смеси.
            Срок схватывания раствора: 120-150 минут.
            Площадь применения: 10 кг/м2, при толщине слоя 1 см.
            Прочность на сжатие: 2,0 МПа.
            Прочность сцепления с основанием: 0,3 МПа.
            Необходимая температура рабочей среды: +5°С/+35°С`,
            img: Product3,
        },
        {
            title: 'Наливной пол',
            description:'Сухая строительная напольная смесь на основе цементного вяжущего с полимерными добавками, изготавливаемая по ГОСТу 31358-2007. Предназначена для устройства элементов пола, самоуплотняющаяся, применяемая при строительстве, реконструкции, ремонте зданий и сооружений. Обладает хорошей адгезией к бетону и к другим основаниям. Антибактериальные свойства  продукции предотвращают появление грибков, мхов и бактерий в помещениях. Легко применяется, не требует лишних трудозатрат. Обладает высокой износостойкостью.',
            usage: `Перемешивание с водой: 2,4 -3,0 литра на 10 кг смеси.
            Время использования:  60 минут.
            Площадь применения: 1,7 кг/м2, при толщине слоя 1 мм.
            Прочность сцепления с основанием: 0,6 МПа.
            Необходимая температура рабочей среды: +5°С/+35°С.`,
            img: Product4,
        },
        {
            title: 'Усиленный клей',
            description:'Готовый сухой клей (смесь) на основе цементного вяжущего с полимерными и экстра добавками. Используется в приклеивании таких отделочных материалов как фаянсовые и керамические плитки, мраморные и гранитные плиты, стекольная мозаичная стяжка и т.п. на поверхность стяжки, бетона в закрытых и открытых местностях, в горизонтальных и вертикальных поверхностях, в бассейне, бане, резервуарах воды и т.п. влажных помещениях. Перепады температуры и обледенение не влияют на свойства клея.',
            usage: `Перемешивание с водой: 2,7-3,5 литр на 10 кг смеси.
            Время использования: 120-180 минут.
            Площадь применения исходя из размеров плитки: 4-5 кг/м2.
            Прочность сцепления с основанием: 0,5 МПа.
            Необходимая температура рабочей среды: +5°С/+35°С.`,
            img: Product5,
        },
    ],
    [
        {
            title: 'Finish putty',
            description:'Dry construction mixis made on plaster binding with various additives in accordance with ГОСТ 31387-2008. It has high adhesive properties. It intends to prepare surfaces for finishing in internal work on concrete, cement screed or gypsum plaster and other surfaces plaster bases. The layer thickness is 1 mm per one application. It makes a smooth surface and even do not need a primer for painting or wallpapering. During the drying process it does not crack. Air flow properties provide the balance of the humidity level.  It is lightweight, provides the thermal insulation. It does not flow and clot. It is fire-prevention characteristics.',
            usage: `Water ratio: 5-6 liters of water per 10 kg of the dry mix.
            Application time: 180-200 minutes.
            Application area: 1 kg /m2, at the thickness of layer of 1 мм.
            The compression strength: 2,0 MPa.
            Adhesion strength with the base: 0,3 MPa.
            Necessary temperature of working environment: +5°С/+35°С.`,
            img: Product1,
        },
        {
            title: 'Gypsum Plaster',
            description:`Dry mortar is based on gypsum, made in accordance with ГОСТ 31377-2008. It intends for interior works: for alignment and plastering stone, brick, plaster, concrete, monolithic concrete, etc. walls. The layer thickness should be from 2 sm till 13 sm. It possesses the high adhesive characteristic. It does not flow and clot. The air flow properties provide the balance the humidity level in the room. It makes available for the thermal insulation. It has energy-saving and fire-prevention characteristics.`,
            usage: `Water ratio: 4,5-5,5 liters of water per 10 kg of the dry mix.
            Application time: not less than 60 minutes.
            Drying time: 24 hours.
            Application area: 10 kg /m2, at 1 sm thickness of layer.
            The compression strength: 2,0 MPa.
            Adhesion strength with the base: 0,3 MPa.
            Necessary temperature of working environment: +5°С/+35°С.`,
            img: Product2,
        },
        {
            title: 'Glatt',
            description:`Universal dry plaster mixture of gypsum is based on the various additives, manufactured in accordance with ГОСТ 31387-2008. It has the high degree of adhesiveness and does not require the additional materials. It can be used directly on the masonry, concrete, gas concrete and etc. for the internal work, construction, repair and reconstruction of buildings and structures with the layer thickness of the walls is 5 sm, and the ceiling is up to 3 sm. It does not flow and clot. Air flow properties provide the balance of the humidity level.  It is lightweight, provides the thermal insulation. It is fire-prevention characteristics.`,
            usage: `Water ratio: 5-6 liters of water per 10 kg of the dry mix.
            Application time: 120-150 minutes.
            Application area: 10 kg /m2, the layer thickness is 1 sm.
            The compression strength: 2,0 MPa.
            Adhesion strength with the base: 0,3 MPa.
            Necessary temperature of working environment: +5°С/+35°С.`,
            img: Product3,
        },
        {
            title: 'Self-leveling floor',
            description:'The dry construction floor mix made on cement knitting with the polymeric additives, manufactured in accordance with ГОСТ 31358-2007. Designed for the device elements of floor, self-sealing, used in the construction, reconstruction, repair of buildings and structures. Possesses the good adhesion to concrete and other bases. The antibacterial properties of products prevent the appearance of fungi, mosses and bacteria in the premises. It is easily applied, does not require superfluous expenditures of labor. Possesses the high wear resistance.',
            usage: `Water ratio: 2,4 -3,0 liters on 10 kg of the mixture.
            Application time: 60 min.
            The application areas: 1,7kg / m2, at 1 mm thickness of layer.
            Adhesion strength with the base: 0,6 MPa.
            Necessary temperature of working environment: +5°С/+35°С.`,
            img: Product4,
        },
        {
            title: 'Adhesive(extra) glue',
            description:'The dry adhesive (mix) is on the basis of cement binder with polymer and extra additives. It uses in the bonding of finishing materials such as porcelain and ceramic tiles, marble and granite slabs, glass mosaic tie and etc. on the surface of the screed, concrete in indoor and outdoor , on the horizontal and vertical surfaces, in the swimming pool, sauna, water tanks and etc. humid premises. The change in temperature and frozen process do not influence on the properties of the adhesive.',
            usage: `Water ratio: 2,7-3,5 liter per 10 kg of the mix.
            Application time: 120-180 minutes.
            Area of application is based on the size of tiles: 4-5 kg / m2.
            Adhesion strength with the base: 0,5 MPa.
            Necessary temperature of working environment: +5°С/+35°С.`,
            img: Product5,
        },
    ],
]