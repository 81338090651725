import React from 'react'
import styled from 'styled-components'
import ProductsIcon from '../images/products-icon.png'
import AboutIcon from '../images/information-icon.png'
import ContactIcon from '../images/blog.png'
import BlogIcon from '../images/communicate.png'
import { Link } from 'react-router-dom'
import logoGif from '../images/JOVI.gif'
import FaceBook from '../images/facebook.png'
import Instagram from '../images/instagram.png'
import Telegram from '../images/telegram.png'
import Phone from '../images/call.png'
import Home from '../images/home.png'

function Footer({ language }) {

    let links = [
        [
            {
                title: 'Asosiy',
                path: '/',
                img:Home,
            },
            {
                title: 'Mahsulotlar',
                path: '/products',
                img: ProductsIcon,
            },
            {
                title: 'Biz Haqimizda',
                path: '/about',
                img: AboutIcon,
            },
            {
                title: 'Blog',
                path: '/blog',
                img: BlogIcon,
            },
            {
                title: "Biz Bilan Bog'laning",
                path: '/contact',
                img: ContactIcon,
            }
        ],
        [
            {
                title: 'Главная',
                path: '/',
                img:Home,
            },
            {
                title: 'Продукты',
                path: '/products',
                img: ProductsIcon,
            },
            {
                title: 'О компании',
                path: '/about',
                img: AboutIcon,
            },
            {
                title: 'Блог',
                path: '/blog',
                img: BlogIcon,
            },
            {
                title: 'Контакты',
                path: '/contact',
                img: ContactIcon,
            }
        ],
        [
            {
                title: 'Main',
                path: '/',
                img:Home,
            },
            {
                title: 'Products',
                path: '/products',
                img: ProductsIcon,
            },
            {
                title: 'About Us',
                path: '/about',
                img: AboutIcon,
            },
            {
                title: 'Blog',
                path: '/blog',
                img: BlogIcon,
            },
            {
                title: 'Contact',
                path: '/contact',
                img: ContactIcon,
            }
        ]
    ]

    return (
        <Wrapper>
            <div className='footer-logo'>
                <img className='' src={logoGif} alt="" />
            </div>
            <ul className='links-wrapper'>
                {links[language].map((link) => <Link key={Math.random()} to={link.path}>
                    <img src={link.img} alt="" />
                    {link.title}
                    </Link>)}
            </ul>
            <div className='messenger-phone'>
                <div className='messenger-icons'>
                    <a target="_blank" href='https://t.me/jovimixtg'>
                        <img src={Telegram} alt="" />
                    </a>
                    <a target="_blank" href='https://www.instagram.com/jovimix_/'>
                        <img src={Instagram} alt="" />
                    </a>
                    <a target="_blank" href='https://www.facebook.com/profile.php?id=100086217144352'>
                        <img src={FaceBook} alt="" />
                    </a>
                </div>
                <div className='phone-wrapper'>
                    <a className='phone-number' href='tel:+998981288485'>
                        <img src={Phone} alt="" />
                        +998981288485
                    </a>
                    <a className='phone-number' href='tel:+998949238888'>
                        <img src={Phone} alt="" />
                        +998949238888
                    </a>
                </div>
            </div>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    padding: 20px;
    background-color: #383D3B;
    margin-top: 0px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    /* position: relative;
    width: calc(100% - 40px);
    bottom: 0; */

    .footer-logo {
        width: 200px;
        height: 200px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;

        img {
            width: 250%;
            height: 250%;
        }
    }

    .links-wrapper {
        padding: 0px;
        display: flex;
        flex-direction: column;
        gap: 15px; 

            a {
                text-decoration: none;
                color: #ffffff;
                cursor: pointer;
                list-style: none;
                display: flex;
                align-items: center;
                gap: 15px;
                border-radius: 4px;
                padding:5px 35px;

                &:hover {
                /* border-bottom: 1px solid #ffffff; */
                box-shadow: 0px 0px 10px 0px white;
                }
            }
    }

    .messenger-phone {
        display: flex;
        gap: 35px;
        flex-direction: column;
        align-items: center; 

        .messenger-icons, .phone-wrapper{
            display: flex;
            gap: 20px;
        }

        .phone-wrapper {
            flex-direction: column;
            
            a {
                display: flex;
                gap: 20px;
                align-items: center;
                text-decoration: none;
                color: white;
            }
        }
    }

    
    @media (max-width:700px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }
`

export default Footer