import React from 'react'
import styled from 'styled-components'
import CallPhone from '../images/phone-call.png'

function Call() {
  return (
    <Wrapper>
        <a href="tel:+998981288485">
            <img src="https://t4.ftcdn.net/jpg/00/82/56/67/240_F_82566747_T7dXb2E3KI6cV5yFekgL7BO58kOtFt6Y.jpg" alt="" />
            {/* <img src="https://cdn-icons-png.flaticon.com/512/455/455705.png" alt="" /> */}
        </a>
    </Wrapper>
  )
}

const Wrapper = styled.div`
    position: fixed;
    bottom: 100px;
    right: 30px;
    width: 58px;
    height: 58px;
    border-radius: 50%;
    box-shadow: 0px 0px 3px 3px #787878;
    overflow: hidden;
    animation: call-animation;
    animation-duration: 2s;
    animation-iteration-count:infinite;

    @keyframes call-animation {
        25% {
            box-shadow: 0px 0px 10px 10px #787878;
            transform: rotate(-45deg);
        }
    }

    img {
        width: 100%;
        height: 100%;
    }

    @media (max-width: 890px) {
        bottom: 130px;
    }
`

export default Call