import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Button } from '@mui/material'
import ThreeSixtyIcon from '@mui/icons-material/ThreeSixty';
import { products } from '../data';

function Products({language}) {

    const [usageDescription, setusageDescription] = useState(true)
    const selectProduct = useRef()
    const [selectedProduct, setSelectedProduct] = useState('')

    let filtered = products[language].filter(product => product.title.includes(selectedProduct))
    

    useEffect(() => {
        AOS.init();
      }, [])

    return (
        <Wrapper>
            <div className='product-head'>
                <h1>
                    {language === 0 ? 'Mahsulotlar' 
                    : language === 1 ? 'Продукты' : 'Products'}
                </h1>
                <form>
                    <input 
                        onChange={() => {
                            setSelectedProduct(selectProduct.current.value)  
                        }}
                        ref={selectProduct}
                        list='products'
                        name='product'
                        id='product'
                        placeholder={language === 0 ? '🔍 Qidirish...' 
                        : language === 1 ? '🔍 Искать...' : '🔍 Search...'}
                        />
                    <datalist id='products'>
                        {products[language].map(product => <option key={Math.random()} value={product.title}/>)}
                    </datalist>
                </form>
            </div>
            <div className='cards-grid-wrapper'>
                {filtered.map(product => 
                    <div className='card' data-aos="zoom-in-up"  key={Math.random()}>
                        <div className='img-wrapper'>
                            <img src={product.img} alt={product.title} />
                        </div>
                        <div className='text-wrapper'>
                            <p className='title'>{product.title}</p>
                            <p className='usage'>{
                            usageDescription  ? product.usage 
                            : product.description}</p>
                             <Button variant="outlined" onClick={() => setusageDescription(!usageDescription)}>
                                {!usageDescription  ? language === 0 ? "Qo'llanilishi" 
                        : language === 1 ? 'Применение' : 'Usage' : language === 0 ? "Tarkibi" 
                        : language === 1 ? 'Состав' : 'Content' } 
                                <ThreeSixtyIcon />
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        </Wrapper>
    )
}

const Wrapper = styled.div`
     padding: 20px;

     @media (max-width:890px) {
        * {
            z-index: unset;
        }
     }

    .product-head {
        display: flex;
        gap: 25px;
        align-items: center;
        justify-content: center;

        h1 {
            color: white;
        }

        div {
            background-color: transparent;
        }

        input {
            min-width: 250px;
            padding: 8px 16px;
            border: 1px solid rgba(56, 116, 203, 1);
            border-radius: 4px;
            outline: rgba(56, 116, 203, 1);
        }

        @media (max-width:550px) {
            flex-direction: column;
            margin-bottom: 20px;
        }
    }

    .cards-grid-wrapper {
        display: grid;
        gap: 95px;
        grid-template-columns: repeat(auto-fit, minmax(300px, 330px));
        justify-content: center;

        .card {
            margin: auto;
            box-shadow: 0px 1px 10px 3px rgba(34, 60, 80, 0.2);
            border-radius: 6px;
            overflow: hidden;
            transition: 0.3s;
            background:#2D302F;

            .img-wrapper {
                height: 400px;
                width: 100%;
                overflow: hidden;
                display: flex;
                align-items: center;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    transition: 0.3s;
                }   

                img:hover {
                    width: 120%;
                    height: 120%;
                }

            }

            .text-wrapper {
                padding: 15px;

                .title{
                    font-style: normal;
                    font-weight: 600;
                    font-size: 32px;
                    line-height: 28px;             
                    letter-spacing: -0.011em;             
                    color: #f6efef;
                }

                .usage {
                    color: #ffffff;
                }
            }

            &:hover {
                box-shadow: 0px 5px 10px 10px rgba(34, 60, 80, 0.2);
            }
        }

        .css-1rwt2y5-MuiButtonBase-root-MuiButton-root {
            color: #9EA6A3;
            border: 1px solid #9EA6A3;
        }

    }
`



export default Products