import styled from "styled-components";
import Home from '../images/home.png'
import ProductsIcon from '../images/products-icon.png'
import AboutIcon from '../images/information-icon.png'
import ContactIcon from '../images/blog.png'
import BlogIcon from '../images/communicate.png'
import { Link } from "react-router-dom";

function TabBar() {
    const list = document.querySelectorAll(".navigation li");
    function activelink() {
        list.forEach((item) => item.classList.remove("active"));
        this.classList.add("active");
    }
    list.forEach((item) => item.addEventListener("click", activelink));

    return (
        <MobileBar>
            <ul class="navigation">
                <Link to={'/'}>
                    <li class="active">
                        <a href="#">
                            <span class="icon">
                                <i>
                                    <img src={Home} alt="" />
                                </i>
                            </span>
                            <span class="text">Home</span>
                        </a>
                    </li>
                </Link>
                <Link to={'/products'}>
                    <li>
                        <a href="#">
                            <span class="icon">
                                <i>
                                    <img src={ProductsIcon} alt="" />
                                </i>
                            </span>
                            <span class="text">Products</span>
                        </a>
                    </li>
                </Link>
                <Link to={'/about'}>
                    <li>
                        <a href="#">
                            <span class="icon">
                                <i>
                                    <img src={AboutIcon} alt="" />
                                </i>
                            </span>
                            <span class="text">About</span>
                        </a>
                    </li>
                </Link>
                <Link to={'/contact'}>
                    <li>
                        <a href="#">
                            <span class="icon">
                                <i>
                                    <img src={ContactIcon} alt="" />
                                </i>
                            </span>
                            <span class="text">Contact</span>
                        </a>
                    </li>
                </Link>
                <Link to={'/blog'}>
                    <li>
                        <a href="#">
                            <span class="icon">
                                <i>
                                    <img src={BlogIcon} alt="" />
                                </i>
                            </span>
                            <span class="text">Blog</span>
                        </a>
                    </li>
                </Link>
                <div class="indicator"></div>
            </ul>
        </MobileBar>
    )
}

const MobileBar = styled.div`
    z-index: 2000 !important;
    position: sticky;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    width: calc(100% - 10px);
    height: 80px;
    background: #383D3B;

.navigation {
    position: relative;
    padding: 5px;
    width: 100%;
    height: 60px;
    border-radius: 30px;
    background: #383D3B;
    box-shadow: 25px 25px 75px rgba(0, 0, 0, 0.25),
      10px 10px 70px rgba(0, 0, 0, 0.25), inset 5px 5px 10px rgba(0, 0, 0, 0.5),
      inset 5px 5px 20px rgba(255, 255, 255, 0.2),
      inset -5px -5px 15px rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.navigation li {
    position: relative;
    list-style: none;
    width: 50px;
    margin: 0 5px;
}

.navigation li::before {
    content: "";
    position: absolute;
    top: 35px;
    left: 50%;
    transform: translateX(-50%);
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: #000000;
    transition: 0.5s;
}

.navigation li.active::before {
    background: #F1F0F0;
    box-shadow: 0 0 5px #F1F0F0, 0 0 10px #F1F0F0, 0 0 20px #F1F0F0,
    0 0 30px #F1F0F0, 0 0 40px #F1F0F0, 0 0 50px #F1F0F0;
}

.navigation li a {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-decoration: none;
}

.navigation li a .icon {
    position: absolute;
    font-size: 1.75em;
    width: 55px;
    height: 55px;
    display: flex;
    color: #aaa;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    transition: 0.5s;
    transition-delay: 0.2s;
}

.navigation li.active a .icon {
    transform: translateY(-25px);
    background: #F1F0F0;
    color: #c6c6c6;
    box-shadow: 5px 5px 7px rgba(0, 0, 0, 0.25),
      inset 2px 2px 3px rgba(255, 255, 255, 0.25),
      inset -3px -3px 5px rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    transition-delay: 0s;
}

.navigation li.active a .icon i {
    z-index: 22;
}

.navigation li a .icon::before {
    content: "";
    position: absolute;
    inset: 6px;
    background: #A19F9F;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5),
      inset 2px 2px 3px rgba(255, 255, 255, 0.25),
      inset -3px -3px 5px rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    transform: scale(0);
    transition: 0.5s;
}

.navigation li.active a .icon::before {
    transform: scale(1);
}

.navigation li a .text {
    position: absolute;
    font-size: 0.75em;
    opacity: 0;
    transform: translateY(20px);
    transition: 0.5s;
    padding: 2px 10px;
    background: #fff;
    border-radius: 15px;
    color: #2f363e;
    box-shadow: 5px 5px 7px rgba(0, 0, 0, 0.25),
      inset -3px -3px 5px rgba(0, 0, 0, 0.5);
    transition-delay: 0s;
}

.navigation li.active a .text {
    opacity: 1;
    transform: translateY(10px);
    transition-delay: 0.2s;
}




    @media (min-width: 890px) {
        & {
            display: none;
        }
    }
`

export default TabBar;