import React, { useEffect } from 'react'
import styled from 'styled-components'
import FaceBook from '../images/facebook.png'
import Instagram from '../images/instagram.png'
import Telegram from '../images/telegram.png'
import AOS from 'aos';
import 'aos/dist/aos.css';

function Blog({language}) {

    useEffect(() => {
        AOS.init();
      }, [])


    return (
        <Wrapper >
            <h1>
                {language === 0 ? 'Ijtimoiy tarmoqlar'
                : language === 1 ? 'Социальные медии' : 'Social medias'}
            </h1>
            <div className='messengers'>
                <a target="_blank" href='https://t.me/jovimixtg' data-aos="fade-right" data-aos-duration="900">
                    <img src={Telegram} alt="" />
                    Telegram
                </a>
                <a target="_blank" className='instagram' href='https://www.instagram.com/jovimix_/' data-aos="fade-left" data-aos-duration="900">
                    <img src={Instagram} alt="" />
                    Instagram
                </a>
                <a target="_blank" className='facebook' href='https://www.facebook.com/profile.php?id=100086217144352' data-aos="fade-right" data-aos-duration="900">
                    <img src={FaceBook} alt="" />
                    Facebook
                </a>
            </div>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    padding: 45px 0px;
    position: relative;


    h1 {
        text-align: center;
        margin-bottom: 35px;
        color: white;
    }

    .messengers {
        display: flex;
        align-items: center;
        gap: 45px;
        flex-direction: column;
        a {
            width: 300px;
            padding: 15px 10px;
            border-radius: 8px;
            box-shadow: 0px 1px 5px 1px #9cbff3,0px 1px 5px 1px #ffffff;
            background: linear-gradient(170deg, #0088cc, #FFFFFF);
            text-decoration: none;
            color: #ffffff;
            font-size: 18px;
            display: flex;
            gap: 35px;
            align-items: center;

            img {
                width: 30px;
            }

            &:hover {
                box-shadow: 0px 2px 20px 0px #9cbff3,0px 2px 20px 0px #ffffff;
            }
        }

        .instagram {
            background:linear-gradient(170deg, #405de6, #405de6, #833ab4, #c13584, #e1306c, #fd1d1d);
            box-shadow: 0px 1px 5px 1px #9cbff3,0px 1px 5px 1px #ffffff;
            color: #ffffff;

            &:hover {
                box-shadow: 0px 2px 20px 0px #9cbff3,0px 2px 20px 0px #ffffff;
            }
        }

        .facebook {
            background:linear-gradient(170deg, #3b5998, #FFFFFF);
        }
    }
`

export default Blog