import React from 'react'
import styled from 'styled-components'
import MenuBtn from '../images/menu-btn.png'
import Cancel from '../images/cancel.png'
import { Link } from 'react-router-dom'
import ProductsIcon from '../images/products-icon.png'
import AboutIcon from '../images/information-icon.png'
import ContactIcon from '../images/blog.png'
import BlogIcon from '../images/communicate.png'
import FaceBook from '../images/facebook.png'
import Instagram from '../images/instagram.png'
import Telegram from '../images/telegram.png'
import Phone from '../images/call.png'
import Home from '../images/home.png'

function BurgerMenu({ language }) {

    let links = [
        [
            {
                title: 'Asosiy',
                path: '/',
                img:Home,
            },
            {
                title: 'Mahsulotlar',
                path: '/products',
                img: ProductsIcon,
            },
            {
                title: 'Biz Haqimizda',
                path: '/about',
                img: AboutIcon,
            },
            {
                title: 'Blog',
                path: '/blog',
                img: BlogIcon,
            },
            {
                title: "Biz Bilan Bog'laning",
                path: '/contact',
                img: ContactIcon,
            }
        ],
        [
            {
                title: 'Главная',
                path: '/',
                img:Home,
            },
            {
                title: 'Продукты',
                path: '/products',
                img: ProductsIcon,
            },
            {
                title: 'О компании',
                path: '/about',
                img: AboutIcon,
            },
            {
                title: 'Блог',
                path: '/blog',
                img: BlogIcon,
            },
            {
                title: 'Контакты',
                path: '/contact',
                img: ContactIcon,
            }
        ],
        [
            {
                title: 'Main',
                path: '/',
                img:Home,
            },
            {
                title: 'Products',
                path: '/products',
                img: ProductsIcon,
            },
            {
                title: 'About Us',
                path: '/about',
                img: AboutIcon,
            },
            {
                title: 'Blog',
                path: '/blog',
                img: BlogIcon,
            },
            {
                title: 'Contact',
                path: '/contact',
                img: ContactIcon,
            }
        ]
    ]

    function openMenu() {
        const menu = document.querySelector('.menu')
        menu.style.display = 'block'
    }

    function hideMenu() {
        const menu = document.querySelector('.menu')
        menu.style.display = 'none'
    }

    return (
        <Wrapper>
            <img onClick={() => openMenu()} src={MenuBtn} alt="" />
            <div className='menu'>
                <button onClick={() => hideMenu()}>
                    <img src={Cancel} alt="-" />
                </button>
                <ul>
                    {links[language].map((link) => <li key={Math.random()}>
                        <img src={link.img} alt="" />
                        <Link onClick={() => hideMenu()} to={link.path}>{link.title}</Link>
                    </li>)}
                </ul>
                <div className='messenger-icons'>
                    <a href="#">
                        <img src={Telegram} alt="" />
                    </a>
                    <a href="#">
                        <img src={Instagram} alt="" />
                    </a>
                    <a href="#">
                        <img src={FaceBook} alt="" />
                    </a>
                </div>
                <div className='phone-wrapper'>
                    <a className='phone-number' href='tel:+998949238888'>
                        <img src={Phone} alt="" />
                        +998949238888
                    </a>
                    <a className='phone-number' href='tel:+998946238888'>
                        <img src={Phone} alt="" />
                        +998946238888
                    </a>
                </div>
            </div>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    display: none;
    position: fixed;
    z-index: 1000;
    top: 45px;
    right:40px;

    .menu {
        padding: 40px 20px;
        background-color: #1e1e1e;
        z-index: 10000000000;
        position: fixed;
        top: 0;
        right: 0;
        width: calc(70vw - 17px);
        height: 100vh;
        display: none;
        transition: 0.3s;
        box-shadow: 0px 2px 10px 2px grey;

        button {
            background-color: transparent;
            border: none;
            overflow: hidden;

            &:hover {
                cursor:pointer;

                img {
                    transition: 0.3s;
                    transform: rotate(180deg);
                }
            }
        }
    
        ul {
            padding: 40px 0;
            display: flex;
            gap: 25px;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            li {
                display: flex;
                align-items: center;
                gap: 10px;

                a { 
                    text-decoration: none;
                    font-size: 18px;
                    color: white;
                }
            }
        }

        .messenger-icons {
            display: flex;
            justify-content: center;
            gap: 20px;
        }

        .phone-wrapper {
            margin: 15px 0;
            display: flex;
            gap: 25px !important;
            flex-direction: column;
            align-items: center;
            gap: 10px;

            .phone-number {
                text-decoration: none;
                color: white;
                display: flex;
                gap: 20px;
                align-items: center;
            }
        }
    }

    @media (max-width:890px) {
        & {
            display: block;
        }
    }
`

export default BurgerMenu