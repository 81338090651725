import { Button, TextField } from '@mui/material'
import React, { useEffect } from 'react'
import styled from 'styled-components'
import Blog from './Blog'
import Phone from '../images/call.png'
import { useRef } from 'react'
import axios from 'axios'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

function Contact({ language }) {

    useEffect(() => {
        AOS.init();
      }, [])

    const user = useRef()
    const phone = useRef()
    const userMessage = useRef()

    function sendMessage() {
        const token = '5683750069:AAFPYz7bPMtbQq3q3nXCOReG3kBeSUeeUPc'
        const chat_id = '-1001840806400'
        const api = `https://api.telegram.org/bot${token}/sendMessage`

        let message = `Message\n`
        message += `User name: ${user.current.value}\n`
        message += `User phone: ${phone.current.value}\n`
        message += `User message: ${userMessage.current.value}`

        // console.log(message)

        axios.post(api, {
            chat_id: chat_id,
            parse_mode: 'html',
            text: message
        })
            .then(() => {
                toast.success(language === 0 ? "Jo'natish amalga oshirildi" 
                : language === 1 ? 'Отправлено' : 'Sent', {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    });
                user.current.value = ''
                phone.current.value = ''
                userMessage.current.value = ''

            })
            .catch(err => {
                toast.error(err, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    });
                user.current.value = ''
                phone.current.value = ''
                userMessage.current.value = ''
            })
    }

    const labels = [
        {
            title: `Biz bilan bog'lanish uchun habar qoldiring yoki quyidagi messenger va telefon raqamlaridan foydalaning`,
            name: 'Ismingiz',
            phone: 'Telefon',
            message: 'Habar',
        },
        {
            title: `Для связи с нами оставьте сообщение или используйте мессенджер и телефоны ниже.`,
            name: 'Ваше имя',
            phone: 'Телефон',
            message: 'Сообщение',
        },
        {
            title: `To contact us, leave a message or use the messenger and phone numbers below.`,
            name: 'Name',
            phone: 'Phone Number',
            message: 'Message',
        }
    ]

    return (
        <Wrapper data-aos="zoom-in-up" data-aos-duration="900">
            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover

            />
            <h3>{labels[language].title}</h3>
            <div className='form'>
                <div className='inputs' >
                    <TextField data-aos="fade-right" data-aos-duration="900" inputRef={user} id="standard-basic" label={labels[language].name} variant="outlined" />
                    <TextField data-aos="fade-left" data-aos-duration="900" inputRef={phone} id="standard-basic" label={labels[language].phone} variant="outlined"  type="number"/>
                    <TextField
                        data-aos="fade-right" data-aos-duration="900"
                        inputRef={userMessage}
                        id="standard-multiline-static"
                        label={labels[language].message}
                        multiline
                        rows={6}
                        defaultValue=''
                        variant="outlined"
                    />
                </div>
                <Button onClick={() => sendMessage()} data-aos="fade-left" data-aos-duration="900">
                    {language === 0 ? "Jo'natish" 
                    : language === 1 ? 'Отправить' : 'Send'}
                </Button>
                <a data-aos="fade-right" data-aos-duration="900" className='phone-number' href='tel:+998981288485'>
                    <img src='https://cdn-icons-png.flaticon.com/512/2948/2948005.png' alt="" />
                    +998981288485
                </a>
                <a data-aos="fade-left" data-aos-duration="900" className='phone-number' href='tel:+998949238888'>
                    <img src='https://cdn-icons-png.flaticon.com/512/2948/2948005.png' alt="" />
                    +998949238888
                </a>
            </div>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    padding: 30px 10px;
    display: flex;
    gap: 30px;
    flex-direction: column;
    align-items: center;
    color: white;
    position: relative;
    text-align: center;

    @media (max-width:890px) {
        * {
            z-index: unset;
        }
    }

    h3 {
        margin: 0 0;
    }

    .toastify {
        width: 100%;
    }

    .form {
        display: flex;
        flex-direction: column;
        gap: 25px;

        .inputs {
            display: flex;
            gap: 20px;
            flex-direction: column;
            align-items: center;

            div {
                width: 300px;

                input, div {
                    border:1px solid white;
                    border-radius: 4px;
                    color: white;
                }

                label {
                    color: whitesmoke;
                }
            }
        }


        .messengers {
            display: flex;
            flex-direction: row;

            @media (max-width: 680px) {
                flex-direction: column;
            }
        }

        button {
            color: #d5d5d5;
            
            &:hover {
                color: black;
                background:rgba(255,255,255,0.3);
            }
        }

        .phone-number {
            display: block;
            display: flex;
            gap: 20px;
            align-items: center;
            justify-content: center;
            text-decoration: none;
            color: #dfdfdf;

            img {
                height: 32px;
            }
        }
    }
`

export default Contact