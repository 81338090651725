import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import navLogo from '../images/nav-logo.svg'
import logoGif from '../images/JOVI.gif'
import logoGifWhite from '../images/JOVI-WHITE.gif'
import Home from '../images/home.png'
import ProductsIcon from '../images/products-icon.png'
import AboutIcon from '../images/information-icon.png'
import ContactIcon from '../images/blog.png'
import BlogIcon from '../images/communicate.png'
import AOS from 'aos';
import 'aos/dist/aos.css';

function Navbar({ language }) {

    useEffect(() => {
        AOS.init();
    }, [])

    let links = [
        [
            {
                title: 'Asosiy',
                path: '/',
                img:Home,
            },
            {
                title: 'Mahsulotlar',
                path: '/products',
                img:ProductsIcon,
            },
            {
                title: 'Biz Haqimizda',
                path: '/about',
                img:AboutIcon,
            },
            {
                title: 'Blog',
                path: '/blog',
                img:BlogIcon,
            },
            {
                title: "Biz Bilan Bog'laning",
                path: '/contact',
                img:ContactIcon,
            }
        ],
        [
            {
                title: 'Главная',
                path: '/',
                img:Home,
            },
            {
                title: 'Продукты',
                path: '/products',
                img:ProductsIcon,
            },
            {
                title: 'О компании',
                path: '/about',
                img:AboutIcon,
            },
            {
                title: 'Блог',
                path: '/blog',
                img:BlogIcon,
            },
            {
                title: 'Свяжитесь с нами',
                path: '/contact',
                img:ContactIcon,
            }
        ],
        [
            {
                title: 'Main',
                path: '/',
                img:Home,
            },
            {
                title: 'Products',
                path: '/products',
                img:ProductsIcon,
            },
            {
                title: 'About Us',
                path: '/about',
                img:AboutIcon,
            },
            {
                title: 'Blog',
                path: '/blog',
                img:BlogIcon,
            },
            {
                title: 'Contact us',
                path: '/contact',
                img:ContactIcon,
            }
        ]
    ]

    return (
        <Wrapper data-aos='zoom-in'>
            <nav>
                <Link className='logo' to={'/'}>
                    <img src={logoGifWhite} alt="logo" />
                </Link>
                <ul className='main-nav'>
                    {links[language].map((link) => <li key={Math.random()}>
                            <img src={link.img} alt="" />
                            <Link to={link.path}>{link.title}</Link>
                    </li>)}
                </ul>   
            </nav>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    background: #383D3B;
    height: 90px;
    position: fixed;
    top: 0;
    z-index: 1000;
    width: 100%;

    nav {
        margin: auto;
        padding: 15px 30px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 100%;

        .logo{
            max-height: 50px;
            width: 150px;
            overflow: hidden;
            display: flex;
            align-items: center;

            img {
                position: relative;
                left: -50px;
                width: 250px;
                filter: drop-shadow(0px 7px 7px white);
            
                &:active {
                filter: drop-shadow(0px 0px 0px white);
                }
        } 
        }

        ul {
            display: flex;
            align-items: center;
            gap: 25px;

           li {
            list-style: none;
            display: flex;
            align-items: center;
            gap: 5px;

            img {
                height: 18px;
            }

            a {
                text-transform: uppercase;
                text-decoration: none;
                font-style: normal;
                font-weight: 300;
                font-size: 16px;
                line-height: 28px;           
                display: flex;
                align-items: center;
                letter-spacing: -0.011em;
                color: #ffffff;
                filter: drop-shadow(1px 1px 1px grey);
                transition: 0.3s;
            }
            a:hover {
                border-bottom: 2px solid  #ffffff;
            }
            a:active {
                filter: drop-shadow(0px 0px 0px );
            }
           }
        }
    }

    @media (max-width:1010px) {
        nav {
            ul {
            gap: 15px;
        }
        }
        li {
            img {
                height: 24px;
            }
        }
    }

    @media (max-width:890px) {
        & {
            padding: 20px 0;
        }

        .main-nav {
            display: none;
        }
    }
`

export default Navbar