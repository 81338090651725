import React, { useEffect, Component } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import {products} from '../data'
import styled from 'styled-components';
import AOS from 'aos';

export default class DemoCarousel extends Component {

    
    render() {

        AOS.init({
            // initialise with other settings
            duration : 2000
          });

        return (
            <Wrapper>
            <Carousel 
                autoPlay={true}
                infiniteLoop={true} 
                emulateTouch={true}
                interval={3000}
                // preventMovementUntilSwipeScrollTolerance={true}
                showIndicators={false}
                stopOnHover={false}
                swipeScrollTolerance={5}
                thumbWidth={100}
                transitionTime={1000}
                useKeyboardArrows={true}
                showArrows={true}
                // centerMode={true}
                // centerSlidePercentage={50}
                >
                {products[this.props.language].map(product => <div data-aos="zoom-in" key={Math.random()}>
                    <img src={product.img} alt={product}/>
                    <p className={product.title}>{product.title}</p>
                </div>)}
            </Carousel>
            </Wrapper>
        );
    }
};

const Wrapper = styled.div`
    padding: 50px 0px;

    @media (max-width:580px) {
        padding: 15px 0px;
    }

    @media (max-width:890px) {
        * {
            z-index: unset;
        }
    }

    .carousel .slider-wrapper.axis-horizontal .slider {
        max-height: 500px;

        li {
            /* padding: 0 150px; */

            div {
                height: 100%;

                img {
                    object-fit: contain;
                    height: 100%;
                    filter: drop-shadow(1px 2px 2px  #9BB7D4);
                }

                p {
                    text-align: center;
                }
            }
        }
    }


    .carousel .thumbs {
        display: flex;
        justify-content: center;

        @media (max-width:600px) {
            & {
                display: none;
            }
        }
    }

    .carousel-status {
        display: none;
    }

    .carousel .control-arrow, .carousel.carousel-slider .control-arrow  {
        width: 60px;
        color: black;

        &:hover {
            opacity: 0.3;
        }
    }


`