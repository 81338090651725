import React, { useEffect } from 'react'
import styled from 'styled-components'
import Slider from './Slider'
import Products from './Products'
import About from './About'
import Blog from './Blog'
import Contact from './Contact'
import AOS from 'aos';
import 'aos/dist/aos.css';

function Main({ language, isMenuOpen }) {

    useEffect(() => {
        AOS.init();
      }, [])

    return (
        <Wrapper>
            <Slider language={language} isMenuOpen={isMenuOpen} />
            <Products  language={language}/>
            {/* <About language={language}/> */}
            {/* <Blog language={language}/> */}
            {/* <Contact language={language}/> */}
            <iframe  data-aos="zoom-in-up"  data-aos-duration="900" src="https://yandex.ru/map-widget/v1/?um=constructor%3A3a2882cc9041214f226cc7c13e6ac2a070bf0b5a6f2781b28d4e128c2a8132a2&amp;source=constructor" width="100%" height="450" frameborder="0"></iframe>
        </Wrapper>
    )
}

const Wrapper = styled.div`

`

export default Main