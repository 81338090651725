import {Routes, Route} from 'react-router-dom'
import Products from './components/Products';
import Contact from './components/Contact';
import About from './components/About';
import Blog from './components/Blog';
import Navbar from './components/Navbar';
import PageNotFound from './components/PageNotFound';
import styled from 'styled-components';
import Main from './components/Main';
import Footer from './components/Footer';
import {useState } from 'react';
import { Button, Tooltip } from '@mui/material'
import BurgerMenu from './components/BurgerMenu';
import Call from './components/Call';
import TabBar from './components/TabBar';
function App() {

    const [language, setLanguage] = useState(0)
    return (
        <Wrapper>
            <Navbar language={language}/>
            <div className='lang'>
                <Tooltip title="UZ" arrow>
                    <Button className='btn' onClick={() => setLanguage(0)}>
                        <img src='https://upload.wikimedia.org/wikipedia/commons/8/84/Flag_of_Uzbekistan.svg' alt='UZ'/>
                    </Button>
                </Tooltip>
                <Tooltip title="RU" arrow>
                    <Button className='btn' onClick={() => setLanguage(1)}>
                        <img src='https://upload.wikimedia.org/wikipedia/commons/thumb/f/f3/Flag_of_Russia.svg/250px-Flag_of_Russia.svg.png' alt='RU'/>
                    </Button>
                </Tooltip>
                <Tooltip title="ENG" arrow>
                    <Button className='btn' onClick={() => setLanguage(2)}>
                        <img src='https://upload.wikimedia.org/wikipedia/commons/8/87/US_flag_49_stars.svg' alt='EN'/>
                    </Button>
                </Tooltip>
            </div>
            <BurgerMenu className='burger-menu' language={language}/>
            <Routes>
                <Route path='/blog' element={<Blog language={language}/>} />
                <Route path='/about' element={<About language={language}/>} />
                <Route path='/contact' element={<Contact language={language}/>} />
                <Route path='/products' element={<Products language={language}/>} />
                <Route path='/' element={<Main language={language}/>}></Route>
                <Route path='/*' element={<PageNotFound language={language}/>} />
            </Routes>
            <Call/>
            <Footer language={language}/>
            <TabBar language={language}/>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    margin: auto;
    

    .lang {
        position: fixed;
        z-index: 1000;
        top: 0px;
        right: 10px;
        button, .btn {
            background: transparent;
            border: none;
            width: 20px;
            height: 30px;
            margin-right: 15px;
            cursor: pointer;
            transition: 0.3s;

            img {
                width: 100%;
                height: 100%;
            }
            
        }
        button:hover {
            overflow: visible;
        }

        p { 
            color: white;
            margin: 0;
            filter: drop-shadow(1px 1px 0.5px black);
        }
    }
`

export default App;
