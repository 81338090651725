import React from 'react'
import styled from 'styled-components'
import NotFound from '../images/404.gif'

function PageNotFound() {
    return (
        <Wrapper>
            <img src={NotFound} alt="" />
        </Wrapper>
    )
}

const Wrapper = styled.div`
    background-color: gray;

    img {
        object-fit: contain;
        width: 100%;
        max-height: 600px;
    }
`

export default PageNotFound